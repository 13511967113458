
.kirby-plugin-oembed {
  position:    relative;
  padding:     0;

  iframe,
  object {
    max-width: 100%;
  }

  img {
    display: block;
    height:  auto;
  }

  &--video {
    background-color: #ddd;
    overflow:         hidden;

    iframe,
    object {
      position: absolute;
      top:      0;
      left:     0;
      width:    100%;
      height:   100%;
    }
  }

  &--error {
    font-size:  .8em;
  }

  &__thumb {
    position: absolute;
    top:      0;
    left:     0;
    width:    100%;
    height:   100%;

    background-repeat:   no-repeat;
    background-position: center center;
    background-size:     cover;

    cursor:  pointer;

    > img {
      position:   absolute;
      top:        50%;
      left:       50%;
      transform:  translate(-50%, -50%);
      transition: opacity .5s ease-in-out;
      opacity:    .7;
    }

    &:hover {
      > img {
        opacity: 1;
      }
    }
  }

}
