.kirby-plugin-oembed {
  position: relative;
  padding: 0;
}
.kirby-plugin-oembed iframe,
.kirby-plugin-oembed object {
  max-width: 100%;
}
.kirby-plugin-oembed img {
  display: block;
  height: auto;
}
.kirby-plugin-oembed--video {
  background-color: #ddd;
  overflow: hidden;
}
.kirby-plugin-oembed--video iframe,
.kirby-plugin-oembed--video object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.kirby-plugin-oembed--error {
  font-size: 0.8em;
}
.kirby-plugin-oembed__thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}
.kirby-plugin-oembed__thumb > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-in-out;
  opacity: 0.7;
}
.kirby-plugin-oembed__thumb:hover > img {
  opacity: 1;
}